// Germany
export const locale = {
  lang: 'de',
  data: {
    TRANSLATOR: {
      SELECT: 'Wähle deine Sprache',
    },
    MENU: {
      NEW: 'Neu',
      ACTIONS: 'Aktionen',
      CREATE_POST: 'Erstellen Sie einen neuen Beitrag',
      PAGES: 'Pages',
      FEATURES: 'Eigenschaften',
      APPS: 'Apps',
      DASHBOARD: 'Instrumententafel',
      ARCHIVE: 'Archiv',
      PROFILE: 'Profil',
      MY_ARCHIVE: 'Mein Archiv',
      OTHER_USER: 'Anderer Nutzer',
      LIVE_VIEW: 'Live Ansicht',
      CUSTOM_LIVE_VIEW: 'Individuelle Live Ansicht',
      CHARTS: 'Diagramme',
    },
    AUTH: {
      GENERAL: {
        SIGN_OUT: 'Ausloggen',
        OR: 'Oder',
        SUBMIT_BUTTON: 'einreichen',
        NO_ACCOUNT: 'Hast du kein Konto?',
        SIGNUP_BUTTON: 'Anmelden',
        FORGOT_BUTTON: 'Passwort vergessen',
        BACK_BUTTON: 'Zurück',
        PRIVACY: 'Privatsphäre',
        LEGAL: 'Legal',
        CONTACT: 'Kontakt',
      },
      LOGIN: {
        TITLE: 'Create Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Nutzername',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN:
          'Es gibt keinen Benutzerdatensatz, der dieser Kennung entspricht. Der Benutzer wurde möglicherweise gelöscht',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        USER_NOT_FOUND: 'Benutzer nicht gefunden!',
        EMAIL_INVALID: 'E-Mail Adresse ist ungültig!',
        PASSWORD_INVALID: 'Ungültiges Passwort!'
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    PROFILE: {
      PROFILE: {
        PROFILE: 'Profil',
        PERSONAL_INFO: 'Benutzerdaten',
        ALLOWED_SUPERVISORS: 'Optional: Einen Supervisor hinzufügen',
        ACCESS_DATA: '(mit Zugriff auf meine Daten)',
        ID: 'Id',
        USER_ROLE: 'Benutzerrolle',
        NAME: 'Name',
        GENDER: 'Geschlecht',
        AGE: 'Alter',
        EMAIL: 'E-Mail',
        UPDATE: 'Update',
        STATS: 'Statistik',
        SESSIONS: 'Messungen',
        DAYS_OF_USAGE: 'Tage in Benutzung',
        CREATED_AT: 'Erstellt am',
        GROUPS: 'Gruppen',
        PLANS: 'Pläne',
        MALE: 'männlich',
        FEMALE: 'weiblich',
        OTHER: 'andere',
        SUPERVISOR_DESC:
          'Sie sind Supervisor, dies umfasst alle Funktionen des Premium-Plans.',
        ABOUT_BASIC:
          'Speichern Sie alle Ihre Messungen lokal und online in der Cloud. Greifen Sie von allen Ihren Geräten aus auf Ihre Messungen zu. Erhalten Sie Zugriff auf das eSense Web Dashboard',
        ABOUT_PREMIUM:
          'Speichern Sie alle Ihre Messungen + Prozeduren lokal und online in der Cloud. Greifen Sie von allen Ihren Geräten aus auf Ihre Messungen + Prozeduren zu. Teilen Sie Ihre Messungen mit anderen Benutzern. Erhalten Sie Zugriff auf das eSense Web Dashboard inkl. Live Streaming.',
        PRICE_RANGE: 'Preisbereich:',
        PREMIUM_PLAN: 'Premium Plan',
        BASIC_PLAN: 'Basis Plan',
        SUBSCRIBED_AT: 'Beginnt am',
        ENDS_AT: 'Endet am',
        ADD_SUPERVISOR: '',
        SUPERVISOR_ID: 'Eingabe Supervisor ID',
        SUPERVISOR_ERROR: 'Diese Aufsichtsperson ist nicht vorhanden',
        SUPERVISOR_ADDED: 'Liste der Aufsichtspersonen aktualisiert',
        ADD_TEXT: 'Hinzufügen',
        USER_MANAGEMENT: 'Benutzerverwaltung',
        VIDEO_CALL: 'Direkter Videoanruf',
        GROUP_CALL: 'Treffen der Gruppe',
        STATUS_ONLINE: 'Online',
        STATUS_OFFILE: 'Offline',
        STATUS_UNKNOWN: 'Unbekannt',
        SUPERVISOR_EXISTS: 'Fehler, dieser Supervisor ist bereits in der Liste',
        DELETE_ACCOUNT: 'Konto löschen',
      },
      NEWS: {
        NEWS: 'Nachrichten',
        CREATE_NEWS: 'Create News',
        TITLE: 'Title',
        CANCEL: 'Abbrechen',
        CREATE: 'Create',
        CONTENT: 'Content',
      },
    },
    ARCHIVE: {
      RECORDINGS: {
        RECORDINGS: 'Aufzeichnungen',
        RECORDING_NAME: 'NAME MESSUNG',
        DATE: 'DATUM (SS:MM:SS)',
        TIME: 'ZEIT (SS:MM:SS)',
        TIME_INCREASE: 'ZUNAHME (SS:MM:SS)',
        TIME_STEADY: 'STETIG (SS:MM:SS)',
        TIME_DECREASE: 'ABNAHME (SS:MM:SS)',
        MAXIMUM: 'MAXIMUM',
        MINIMUM: 'MINIMUM',
        AVERAGE_BREATH_PER_MINUTE: 'Atemzüge / Minute',
        DIFFERENCE: 'DIFFERENZ MIN/MAX',
        TOTAL_SCR: 'GESAMT SCR',
        SCR_OF_SESSION: '% SCR DER SITZUNG',
        ELECTRODE_FIXATION: 'ELECTRODE FIXATION',
        STRESS_INDEX: 'STRESS INDEX',
        AVERAGE_HR: 'DURCHSCHNITTLICH HR',
        AVERAGE_RR: 'DURCHSCHNITTLICH RR',
        CORRECTED_HR_VALUES: 'Korrigierte Werte zähen',
        CORRECTED_HR_VALUES_IN_PERCENTAGE: 'Korrigierte Werte Prozent',
        COHERENCE: 'KOHÄRENZ',
        TEMPERATURE_CURRENT: 'AKTUELL',
        MAXIMUM_30S: 'ATEMTIEFE',
        TEMPERATURE_MAXIMUM: 'MAXIMUM',
        TEMPERATURE_MINIMUM: 'MINIMUM',
        TEMPERATURE_DIFFERENCE: 'DIFFERENZ MIN/MAX',
        AVERAGE_VALUE: 'DURCHSCHNITT SESSION',
        INCREASE_TIME: 'ZUNAHME (SS:MM:SS)',
        DECREASE_TIME: 'ABNAHME (SS:MM:SS)',
        STEADY_TIME: 'STETIG (SS:MM:SS)',
        INCREASE_TIME_HRV: 'Zunahme der HRV Amplitude',
        STEADY_TIME_HRV: 'Stetige HRV Amplitude',
        DECREASE_TIME_HRV: 'Abnahme der HRV Amplitude',
        SCR_LOW: '0-5 SCR/MIN (SS:MM:SS)',
        SCR_AVG: '6-9 SCR/MIN (SS:MM:SS)',
        SCR_HIGH: '10-15 SCR/MIN (SS:MM:SS)',
        SCR_MAX: '>16 SCR/MIN (SS:MM:SS)',
        SCR_MIN_VALUE: 'SCR/MIN',
        SCR_COVERAGE: '% SCR DER SITZUNG',
        SCR_TOTAL: 'GESAMT SCR',
        SCR: 'SCR',
        BREATH_PER_MINUTE: 'ATEMZÜGE PRO MINUTE',
        DEAPTH_OF_BREATH: 'ATEMTIEFE',
        AVERAGE_OF_SESSION: 'DURCHSCHNITT SESSION',
        HOW_DO_YOU_FEEL: 'WIE FÜHLEN SIE SICH?',
        HEARTS: 'Hearts',
        SCORE: 'ERGEBNIS',
        NO_RECORDINGS: 'Keine Aufnahmen',
        DEPTH_OF_BREATH: 'ATEMTIEFE',
        LOAD_MORE: 'Mehr laden',
        DOWNLOAD: 'Herunterladen',
        USER: 'Benutzer',
        FILTER: 'Filter',
        LOST_PACKETS: 'Verlorene Datenpakte in %',
        REFRESH_INTERVAL: 'Aktualisierungsintervall',
        CH1_MAX: 'Kanal 1 MAXIMUM',
        CH1_MIN: 'Kanal 1 MINIMUM',
        CH2_MAX: 'Kanal 2 MAXIMUM',
        CH2_MIN: 'Kanal 2 MINIMUM',
        SELECTED_CHANNEL: 'Gewählter Kanal',
        CH1: 'Kanal 1',
        CH2: 'Kanal 2',
        BOTH_CHANNELS: 'Kanal 1 + Kanal 2',
        AVERAGE: 'DURCHSCHNITT SESSION',
        MINMV: 'Minimum mV',
        MAXMV: 'Maximum mV'
      },
      ANALYZE: {
        ANALYZE: 'Analysieren',
        NO_ANALYZE: 'Keine Analyse',
        NO_CHART_SELECTED: 'Kein Diagramm ausgewählt',
        CHOOSE_A_DATE: 'Wähle ein Datum'
      },
      SHARED_RECORDS: {
        SHARED_RECORDS: 'gemeinsame Datensätze',
        NO_SHARED_RECORDS: 'Keine gemeinsamen Aufzeichnungen',
      },
    },
    OTHER_USER: {
      USER_ID: 'Benutzer ID oder E-Mail',
      SEARCH_FOR_IDEMAIL: ' Suchen nach ID/E-mail',
      OTHER_USER_HINT: 'Bitte wählen Sie mit einem Klick auf das eSense Logo den gewünschten Sensortyp aus und dann aus der Liste einen Benutzer, dessen Archiv Sie betrachten wollen.'
    },
    LIVE_VIEW: {
      NO_LIVE_VIEW: 'Keine Live Ansicht',
      TIME: 'Zeit (SS:MM:SS)',
      MAXIMUM: 'Maximum',
      MINIMUM: 'Minimum',
      BREATH_PER_MINUTE: 'Atemzug/Min',
      DIFFERENCE: 'Differenz Min/Max',
      COHERENCE: 'Kohärenz',
      BREATHING: 'Atmung',
      AVERAGE_OF_SESSION: 'Durchschnitt der Sitzung',
      CURRENT_VALUE: 'Aktueller Wert',
      REGULARITY: 'Gleichmäßigkeit',
      CURRENT_CALCULATED_RA: 'Berechnete Atemamplitude',
      CHANNEL_TEXT: 'Kanal',
      SELECT_SERVER: 'Server auswählen'
    },
    CUSTOM_CHARTS: {
      USER_ID_OR_EMAIL: 'Nutzer ID oder E-Mail',
      ADD_CUSTOM_CHART: 'Indviduellen Chart hinzufügen',
      TYPE: 'Typ',
      SAVE_CUSTOM_PATTERN: 'Speichere Layout',
      HINT_TEXT: 'Bitte wählen Sie den Sensor Typ aus der Liste und dann einen Benutzer, den Sie für ein Live Streaming mit diesem Sensor sehen wollen. Dieser Nutzer muss dazu einen Premium Plan haben, mit der mobilen eSense App eingeloggt sein und in den Einstellungen der eSense App das Live Streaming aktiviert haben. Und natürlich ein freies Training oder eine Prozedur laufen haben, dann sehen Sie hier die Live Messdaten dieses Benutzers.'
    },
    USER_PROFILE: {
      USER_PROFILE: 'Benutzerprofil',
      USER_ROLE: 'Benutzerrolle',
    },
    GROUPS: {
      MY_GROUPS: 'Meine Gruppen',
      PUBLIC_GROUPS: 'Öffentliche Gruppen',
      CREATE_GROUP: 'Gruppe erstellen',
      NAME: 'Name',
      OK: 'Ok',
      CANCEL: 'Abbrechen',
      ADD_USER: 'Benutzer zur Gruppe hinzufügen',
      GROUP_MEMBERS: 'Mitglieder der Gruppe',
      DELETE: 'Löschen',
      JOIN_GROUP: 'Gruppe beitreten',
      LEAVE_GROUP: 'Gruppe verlassen',
      DELETE_GROUP_TEXT: 'Sind Sie sicher, dass Sie diese Gruppe löschen wollen?',
      FIND_USER: 'Benutzer finden',
      PROCEDURES: 'Prozeduren:'
    },
    RECORDS_TABLE: {
      file_name: 'Name',
      dateCreated: 'Datum',
      recordingTime: 'Dauer'
    },
    SEARCH_TEXT: 'Suche',
    PREMIUM_USERS: {
      PREMIUM_USERS: 'Premium-Benutzer',
      CHOOSE_DATE: 'Das endgültige datum festzulegen',
      USER_INFO: 'Benutzer-Infos',
      ROLE: 'Rolle',
      ROLES: 'Rolle des Benutzers',
      PERIOD: 'Zeitraum',
      UNLIMITED: 'Unbegrenzt',
      NO_USER_OR_INPUT: 'Kein Benutzer gefunden oder ungültige Eingabe',
      SET_PERIOD: {
        SET_BACK_TO_NORMAL: 'Zurücksetzen auf normal',
        SET_USER_PREMIUM: 'Prämienzeitraum für Benutzer festlegen',
        SET_PREMIUM_PERIOD: 'Prämienzeitraum festlegen'
      },
      PREMIUM_START: 'Premium-Start',
      PREMIUM_END: 'Premium Ende',
      SWITCH_TO_NORMAL : 'Zu normal wechseln',
      ACTION : 'Aktion',
      DO_YOU_REALLY_WANT_TO_CHANGE : 'Wollen Sie die Benutzerrolle wirklich auf normal ändern?',
      CONFIRM:'Bestätigen',
      CANCEL: 'Abbrechen',
      USER_ROLE_SWITCHING : 'Der Wechsel der Benutzerrolle ist erfolgreich'
    },
    DELETE_USER: {
      HEADING: 'Sind Sie sicher, dass Sie Ihr Konto und alle Benutzerdaten löschen möchten?',
      CONFIRM: 'Bestätigen',
      CLOSE: 'Abbrechen',
      YES: 'Ja',
      NO: 'Nein',
      ANSWER: 'Antwort'
    },
  },
};
