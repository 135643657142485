<div
  class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
>
  <!-- <span class="font-weight-bold text-dark-50">
    Create free account
  </span> -->
  <a
    href="javascript:;"
    routerLink="/auth/register"
    class="font-weight-bold ml-2 create-button"
    id="kt_login_signup"
  >{{ "Create free account" | translate }}</a
  >
</div>
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <div clbbass="text-center mb-10 mb-lg-20">
      <h3 class="font-size-h1">Login to your account</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <!--begin::Form-->
    <form
      class="form"
      [formGroup]="loginForm"
      autocomplete="off"
      novalidate="novalidate"
      id="kt_login_form"
    >
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.EMAIL" | translate }}</mat-label>
          <input
            matInput
            type="email"
            placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>{{ "AUTH.VALIDATION.INVALID_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minlength')">
            <strong
            >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxlength')">
            <strong
            >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 320</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}</mat-label>
          <input
            matInput
            type="password"
            placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong
            >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong
            >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div
        class="form-group d-flex flex-wrap justify-content-between align-items-center"
      >
        <button
          style="width: 100%"
          id="kt_login_signin_submit"
          (click)="submit()"
          [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }"
          class="btn btn-primary font-weight-bold px-9 py-4 my-3"
        >
          LOGIN
        </button>

<!--        <button-->
<!--          style="width: 100%"-->
<!--          id="kt_login_signin_submit"-->
<!--          (click)="loginWithGoogle()"-->
<!--          [ngClass]="{-->
<!--            'spinner spinner-right spinner-md spinner-light': loadingGoogle-->
<!--          }"-->
<!--          class="btn btn-primary font-weight-bold px-9 py-4 my-3"-->
<!--        >-->
<!--          <img-->
<!--            style="width: 20px; margin-right: 10px"-->
<!--            src="assets/google-icon.png"-->
<!--            alt=""-->
<!--          />-->
<!--          REGISTER & LOGIN WITH GOOGLE+-->
<!--        </button>-->
        <button id="sign-in-with-apple-button" class="apple-sign-in btn btn-primary font-weight-bold px-9 py-4 my-3" (click)="apple($event)">
          <img src="./assets/images/apple-logo.svg" alt="logo"> Sign in with Apple
          <!--          <img src="assets/images/appleid_button@2x.png" alt="img">-->
        </button>
        <script type="text/javascript" src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"></script>
        <br />
        <br />
        <br />
        <div style="display: flex; justify-content: space-between; width: 100%">
          <a style="font-size: 12px" (click)="onNavigate(details?.features)">
            <img
              width="20px"
              src="./../../../../../assets/images/youtube.png"
              alt=""
            />
            Explaining the account features
          </a>
          <a style="font-size: 12px" (click)="onNavigate(details?.dashboard)">
            <img
              width="20px"
              src="./../../../../../assets/images/youtube.png"
              alt=""
            />
            The new eSense Dashboard
          </a>
        </div>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>
