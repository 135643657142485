// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      PROFILE: 'Profile',
      ARCHIVE: 'Archive',
      LIVEVIEW: 'Live view',
      MY_ARCHIVE: 'My Archive',
      OTHER_USER: 'Other User',
      LIVE_VIEW: 'Live View',
      CUSTOM_LIVE_VIEW: 'Custom Live View',
      CHARTS: 'Charts',
      BLUETOOTH: 'Bluetooth'
    },
    AUTH: {
      GENERAL: {
        SIGN_OUT: 'Sign out',
        OR: 'Or',
        SUBMIT_BUTTON: 'CREATE FREE ACCOUNT',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot your password?',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Your Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
        FAILURE: 'Sorry, Try Agian.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'E-Mail',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'There is no user record corresponding to this identifier. The user may have been deleted',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        USER_NOT_FOUND: 'User not found',
        EMAIL_INVALID: 'E-Mail address is invalid',
        PASSWORD_INVALID: 'Invalid password'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    PROFILE: {
      PROFILE: {
        PROFILE: 'Profile',
        ALLOWED_SUPERVISORS: 'My Supervisors',
        ACCESS_DATA: '(With access to my data)',
        PERSONAL_INFO: 'Personal Info',
        ID: 'Id',
        USER_ROLE: 'User role',
        NAME: 'Name',
        GENDER: 'Gender',
        MALE: 'Male',
        FEMALE: 'Female',
        OTHER: 'Other',
        AGE: 'Age',
        EMAIL: 'Email',
        UPDATE: 'Update',
        STATS: 'Stats',
        SESSIONS: 'Sessions',
        DAYS_OF_USAGE: 'Days of usage',
        CREATED_AT: 'Created at',
        GROUPS: 'Groups',
        PLANS: 'Plans',
        SUPERVISOR_DESC: 'You are a supervisor, this includes all features of the premium plan.',
        ABOUT_BASIC: 'Store all your sessions local and online in the cloud. Access your sessions from all your devices.',
        ABOUT_PREMIUM: 'Store all your sessions + procedures local and online in the cloud. Access your sessions + procedures from all your devices. Share your sessions with other users. Get access to online dashboard.',
        PRICE_RANGE: 'Price range:',
        PREMIUM_PLAN: 'Premium plan',
        BASIC_PLAN: 'Basic plan',
        SUBSCRIBED_AT: 'Subscribed at',
        ENDS_AT: 'Ends at',
        ADD_SUPERVISOR: 'Add another supervisor',
        SUPERVISOR_ID: 'Enter supervisor ID',
        SUPERVISOR_ERROR: 'This supervisor is not exists',
        SUPERVISOR_ADDED: 'The request has been sent to a supervisor',
        ADD_TEXT: 'Add',
        USER_MANAGEMENT: 'User management',
        VIDEO_CALL: 'Direct video call',
        GROUP_CALL: 'Group meeting',
        STATUS_ONLINE: 'Online',
        STATUS_OFFILE: 'Away',
        STATUS_UNKNOWN: 'Unknown',
        SUPERVISOR_EXISTS: 'Error, this supervisor already in list',
        DELETE_ACCOUNT: 'Delete account',
      },
      NEWS: {
        NEWS: 'NEWS',
        CREATE_NEWS: 'Create News',
        TITLE: 'Title',
        CANCEL: 'Cancel',
        CREATE: 'Create',
        CONTENT: 'Content'
      }
    },
    ARCHIVE: {
      RECORDINGS: {
        RECORDINGS: 'Recordings',
        SHARED_RECORDS: 'Shared Records',
        RECORDING_NAME: 'RECORDING NAME',
        DATE: 'DATE (HH:MM:SS)',
        TIME: 'TIME (HH:MM:SS)',
        TIME_INCREASE: 'TIME INCREASE (HH:MM:SS)',
        TIME_STEADY: 'TIME STEADY (HH:MM:SS)',
        TIME_DECREASE: 'TIME DECREASE (HH:MM:SS)',
        MAXIMUM: 'MAXIMUM',
        MINIMUM: 'MINIMUM',
        AVERAGE_BREATH_PER_MINUTE: 'AVERAGE BREATH PER MINUTE',
        DIFFERENCE: 'DIFFERNCE MIN/MAX',
        TOTAL_SCR: 'TOTAL SCR',
        SCR_OF_SESSION: '% OF SCR OF SESSION',
        ELECTRODE_FIXATION: 'ELECTRODE FIXATION',
        STRESS_INDEX: 'STRESS INDEX',
        AVERAGE_HR: 'AVERAGE HR',
        AVERAGE_RR: 'AVERAGE RR',
        CORRECTED_HR_VALUES: 'CORRECTED HR VALUES',
        CORRECTED_HR_VALUES_IN_PERCENTAGE: 'CORRECTED HR VALUES IN %',
        COHERENCE: 'COHERENCE',
        BREATH_PER_MINUTE: 'BREATH PER MINUTE',
        AVERAGE_OF_SESSION: 'AVERAGE OF SESSION',
        TEMPERATURE_CURRENT: 'CURRENT VALUE',
        MAXIMUM_30S: 'DEPTH OF BREATH',
        TEMPERATURE_MAXIMUM: 'MAXIMUM',
        TEMPERATURE_MINIMUM: 'MINIMUM',
        TEMPERATURE_DIFFERENCE: 'DIFFERENCE MIN/MAX',
        AVERAGE_VALUE: 'AVERAGE OF SESSION',
        INCREASE_TIME: 'TIME INCREASE (HH:MM:SS)',
        DECREASE_TIME: 'TIME DECREASE (HH:MM:SS)',
        STEADY_TIME: 'TIME STEADY (HH:MM:SS)',
        INCREASE_TIME_HRV: 'Increase of HRV amplitude',
        STEADY_TIME_HRV: 'Steady of HRV amplitude',
        DECREASE_TIME_HRV: 'Decrease of HRV amplitude',
        SCR_LOW: '0-5 SCR/MIN (HH:MM:SS)',
        SCR_AVG: '6-9 SCR/MIN (HH:MM:SS)',
        SCR_HIGH: '10-15 SCR/MIN (HH:MM:SS)',
        SCR_MAX: '>16 SCR/MIN (HH:MM:SS)',
        SCR_MIN_VALUE: 'SCR/MIN',
        SCR_COVERAGE: '% of SCR OF SESSION',
        SCR_TOTAL: 'TOTAL SCR',
        SCR: 'SCR',
        CURRENT_CALCULATED_RA: 'CURRENT CALCULATED RA',
        AVERAGE_REGULARITY: 'AVERAGE REGULARITY',
        HOW_DO_YOU_FEEL: 'HOW DO YOU FEEL?',
        HEARTS: 'Hearts',
        NO_RECORDINGS: 'No Recordings',
        SCORE: 'SCORE',
        USER_ID: 'User id or E-Mail',
        DEPTH_OF_BREATH: 'DEPTH OF BREATH',
        LOAD_MORE: 'Load more',
        DOWNLOAD: 'Download',
        USER: 'User',
        FILTER: 'Filter',
        LOST_PACKETS: 'Lost packets percentage',
        REFRESH_INTERVAL: 'Refresh interval',
        CH1_MAX: 'Channel 1 MAXIMUM',
        CH1_MIN: 'Channel 1 MINIMUM',
        CH2_MAX: 'Channel 2 MAXIMUM',
        CH2_MIN: 'Channel 2 MINIMUM',
        SELECTED_CHANNEL: 'Selected channel',
        CH1: 'Channel 1',
        CH2: 'Channel 2',
        BOTH_CHANNELS: 'Channel 1 + Channel 2',
        AVERAGE: 'Average',
        MINMV: 'Minimum mV',
        MAXMV: 'Maximum mV'
      },
      ANALYZE: {
        ANALYZE: 'Analyze',
        NO_ANALYZE: 'No Analyze',
        NO_CHART_SELECTED: 'No Chart Selected',
        CHOOSE_A_DATE: 'Choose a date'
      },
      SHARED_RECORDS: {
        SHARED_RECORDS: 'Shared Records',
        NO_SHARED_RECORDS: 'No Shared Records'
      }
    },
    OTHER_USER: {
      USER_ID: 'User id or E-Mail',
      SEARCH_FOR_IDEMAIL: 'Search for id/email',
      OTHER_USER_HINT: 'Please select the desired sensor type by clicking on the eSense logo and then select a user from the list whose archive you want to view.'
    },
    LIVE_VIEW: {
      NO_LIVE_VIEW: 'No Live view',
      TIME: 'Time (HH:MM:SS)',
      MAXIMUM: 'Maximum',
      MINIMUM: 'Minimum',
      DIFFERENCE: 'Differnce Min/Max',
      COHERENCE: 'Coherence',
      BREATH_PER_MINUTE: 'Breaths/Min',
      BREATHING: 'Breathing',
      AVERAGE_OF_SESSION: 'Average of Session',
      CURRENT_VALUE: 'Current Value',
      REGULARITY: 'Regularity',
      CURRENT_CALCULATED_RA: 'Current Calculated RA',
      CHANNEL_TEXT: 'Channel',
      SELECT_SERVER: 'Select server'
    },
    CUSTOM_CHARTS: {
      USER_ID_OR_EMAIL: 'User ID or E-Mail',
      ADD_CUSTOM_CHART: 'Add custom Chart',
      TYPE: 'Type',
      SAVE_CUSTOM_PATTERN: 'Save Current Pattern',
      HINT_TEXT: 'Please select the sensor type from the list and then a user that you want to see for live streaming with this sensor. This user must have a Premium plan, be logged in with the eSense mobile app and have activated live streaming in the eSense app settings. And of course have an open training or procedure running, then you will see the live measurement data of this user here.'
    },
    USER_PROFILE: {
      USER_PROFILE: 'User Profile',
      USER_ROLE: 'User role',
    },
    GROUPS: {
      MY_GROUPS: 'My groups',
      PUBLIC_GROUPS: 'Public  groups',
      CREATE_GROUP: 'Create group',
      NAME: 'Name',
      OK: 'Ok',
      CANCEL: 'Cancel',
      ADD_USER: 'Add user to group',
      GROUP_MEMBERS: 'Group members',
      DELETE: 'Delete',
      JOIN_GROUP: 'Join group',
      LEAVE_GROUP: 'Leave group',
      DELETE_GROUP_TEXT: 'Are you sure want to delete this group?',
      FIND_USER: 'Find user',
      PROCEDURES: 'Procedures:'
    },
    RECORDS_TABLE: {
      file_name: 'Name',
      dateCreated: 'Date',
      recordingTime: 'Duration'
    },
    SEARCH_TEXT: 'Search',
    PREMIUM_USERS: {
      PREMIUM_USERS: 'Premium Users',
      CHOOSE_DATE: 'Determine the final date',
      USER_INFO: 'User Info',
      ROLE: 'Role',
      ROLES: 'User role',
      PERIOD: 'Period',
      NO_USER_OR_INPUT: 'No user found or invalid input',
      UNLIMITED: 'Unlimited',
      SET_PERIOD: {
        SET_BACK_TO_NORMAL: 'Set back to normal',
        SET_USER_PREMIUM: 'Set user premium period',
        SET_PREMIUM_PERIOD: 'Set premium Period'
      },
      PREMIUM_START: 'Premium start',
      PREMIUM_END: 'Premium end',

      SWITCH_TO_NORMAL : 'Switch to normal',
      ACTION : 'Action',
      DO_YOU_REALLY_WANT_TO_CHANGE : 'Do you really want to change the user role to normal?',
      CONFIRM:'Confirm ',
      CANCEL: 'Cancel',
      USER_ROLE_SWITCHING : 'User role switching is successful'
    },
    DELETE_USER: {
      HEADING: 'Are you sure want to delete your account and all user data?',
      CONFIRM: 'Confirm',
      CLOSE: 'Abort',
      YES: 'Yes',
      NO: 'No',
      ANSWER: 'Answer'
    },
  }
};
